/*global $ document */
/*eslint-env browser*/

/**
 * Class representing a menu.
 */
class Menu {
  /**
   * Create menu
   */
  constructor() {
    this.bodyElement = $('body');
    this.openButton = $('#open-button');
    this.wrapper = $('#content-wrapper');
    this.isOpen = false;

    this.openButton.on('click', this.toggle.bind(this));

    this.wrapper.on('click', function(ev) {
      if (this.isOpen && $(ev.target) !== this.openButton) {
        this.toggle();
      }
    }.bind(this));
  }

  /**
   * Toggle menu: open/close
   */
  toggle() {
    if (this.isOpen) {
      this.bodyElement.removeClass('show-menu');
    } else {
      this.bodyElement.addClass('show-menu');
    }
    this.isOpen = !this.isOpen;
  }
}

$(document).ready(function() {
  new Menu();
});