'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/*global $ document */
/*eslint-env browser*/

/**
 * Class representing a menu.
 */

var Menu = function () {
  /**
   * Create menu
   */

  function Menu() {
    _classCallCheck(this, Menu);

    this.bodyElement = $('body');
    this.openButton = $('#open-button');
    this.wrapper = $('#content-wrapper');
    this.isOpen = false;

    this.openButton.on('click', this.toggle.bind(this));

    this.wrapper.on('click', function (ev) {
      if (this.isOpen && $(ev.target) !== this.openButton) {
        this.toggle();
      }
    }.bind(this));
  }

  /**
   * Toggle menu: open/close
   */


  _createClass(Menu, [{
    key: 'toggle',
    value: function toggle() {
      if (this.isOpen) {
        this.bodyElement.removeClass('show-menu');
      } else {
        this.bodyElement.addClass('show-menu');
      }
      this.isOpen = !this.isOpen;
    }
  }]);

  return Menu;
}();

$(document).ready(function () {
  new Menu();
});